import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../constant/constance'
import DataTable from 'react-data-table-component'

function BankDetails() {
    const [bankData, setBankData] = useState([])
    const [isupdated, setIsupdated] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    // Filtered data
    const filteredItems = bankData.filter(
        (item) => item.fullName && item.fullName.toLowerCase().includes(filterText.toLowerCase())
    )


    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '50px',
            compact: true,
            style: {
                paddingLeft: '24px',
            },
            headCell: {
                style: {
                    paddingLeft: '24px',
                },
            },
        },
        {
            name: 'Full Name',
            selector: (row) => row.fullName,
            wrap: true,
            minWidth: '220px',
            compact: true
        },
        // {
        //     name: 'Email',
        //     selector: (row) => row.email,
        //     wrap: true,
        //     minWidth: '220px',
        //     compact: true
        // },
        {
            name: 'Bank Name',
            selector: (row) => row.bankName,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Account Number',
            selector: (row) => row.accountNumber,
            wrap: true,
            minWidth: '160px',
            compact: true
        },
        {
            name: 'IFSC Code',
            selector: (row) => row.ifscCode,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        // {
        //     name: 'Username',
        //     selector: (row) => row.username,
        //     wrap: true,
        //     minWidth: '120px',
        //     compact: true
        // },
    ]

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/creatorreg/bankdata`)
                setBankData(response.data.data)
            } catch (error) {
                console.error('Error fetching creator data:', error)
            }
        }
        fetchBankData()
    }, [isupdated])


    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-3 sm:p-4 rounded-lg shadow-sm border border-gray-200 transition-all duration-300 hover:shadow-md">
            <div className="flex justify-between items-center mb-3">
                <span className="text-sm font-semibold text-gray-600">#{index + 1}</span>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <DataRow label="Full Name" value={data.fullName} />
                {/* <DataRow label="Email" value={data.email} /> */}
                <DataRow label="Bank Name" value={data.bankName} />
                <DataRow label="Account Number" value={data.accountNumber} />
                <DataRow label="IFSC Code" value={data.ifscCode} />
                {/* <DataRow label="Username" value={data.username} /> */}
            </div>
        </div>
    );

    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-0.5 transition-all duration-300">
            <span className="text-xs font-medium text-gray-500 transition-colors duration-300">{label}</span>
            <span className="text-sm text-gray-900 break-words transition-colors duration-300">{value || '-'}</span>
        </div>
    );

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 border rounded w-full max-w-lg text-sm outline-none transition-all duration-300"
                />
            </div>

            {/* Mobile View */}
            <div className="xl:hidden px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="grid gap-3 sm:gap-4 transition-all duration-300">
                    {(filterText.length > 0 ? filteredItems : bankData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden xl:block px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="border rounded shadow-sm overflow-hidden transition-all duration-300">
                    <div className='overflow-x-auto'>
                        <DataTable
                            title="Bank Details"
                            columns={columns}
                            data={filterText.length > 0 ? filteredItems : bankData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            responsive
                            customStyles={{
                                table: {
                                    style: {
                                        minWidth: '100%',
                                        transition: 'all 0.3s ease-in-out',
                                        overflowX: 'auto'
                                    },
                                },
                                rows: {
                                    style: {
                                        minHeight: '52px',
                                        fontSize: '13px',
                                        backgroundColor: 'white',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: '#f9fafb',
                                        },
                                        borderBottom: '1px solid #f0f0f0',
                                    },
                                },
                                headRow: {
                                    style: {
                                        backgroundColor: '#f8f9fa',
                                        minHeight: '48px',
                                        borderBottom: '2px solid #e5e7eb',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                },
                                headCells: {
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        padding: '12px 24px',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                    firstChild: {
                                        paddingLeft: '24px',
                                    },
                                },
                                cells: {
                                    style: {
                                        padding: '12px 24px',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                    firstChild: {
                                        paddingLeft: '24px',
                                    },
                                },
                            }}
                            striped={false}
                            highlightOnHover
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BankDetails;