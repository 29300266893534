import React, { Fragment, useState } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { HiOutlineBell, HiOutlineChatAlt, HiMenuAlt1 } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import axios from 'axios'
import { API_URL } from '../../constant/constance'
import { toast } from 'react-toastify'

export default function Header({ logout, toggleSidebar }) {
	const navigate = useNavigate()
	const [messageForm, setMessageForm] = useState({
		subject: '',
		message: ''
	});

	const handleSendMessage = async () => {
		try {
			const messageData = {
				subject: messageForm.subject,
				message: messageForm.message
			};

			await axios.post(`${API_URL}/api/messages/send-message`, messageData);
			toast.success('Message sent successfully');
			setMessageForm({ subject: '', message: '' });
		} catch (error) {
			toast.error('Failed to send message');
		}
	};

	const handleLogout = async () => {
		try {
			const response = await axios.get(`${API_URL}/api/auth/admin_logout`);
			toast.success(response.data.message);
			logout();
			setTimeout(() => {
				navigate("/");
			}, 500);
		} catch (error) {
			toast.error(error.response.data.message)
		}
	}

	return (
		<div className="bg-white h-auto min-h-[64px] px-2 sm:px-4 py-2 flex items-center border-b border-gray-200">
			{/* Left side container for mobile, full width for desktop */}
			<div className="flex items-center gap-2 w-full xl:w-auto">
				{/* Hamburger menu */}
				<button
					onClick={toggleSidebar}
					className="lg:hidden p-1.5 hover:bg-gray-100 rounded-md"
				>
					<HiMenuAlt1 className="w-5 h-5" />
				</button>

				{/* Container for buttons that move right on desktop */}
				<div className="flex items-center gap-2 xl:hidden">
					<Popover className="relative">
						{({ open }) => (
							<>
								<Popover.Button
									className={classNames(
										open && 'bg-gray-100',
										'group inline-flex items-center rounded-sm p-2 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
									)}
								>
									<HiOutlineChatAlt className="w-5 h-5" />
								</Popover.Button>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="fixed left-1/2 transform -translate-x-1/2 w-[95%] md:w-[400px] lg:absolute lg:left-auto lg:right-0 lg:w-[280px] lg:transform-none top-[64px] z-10">
										<div className="mx-2 md:mx-auto bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
											<strong className="text-gray-700 font-medium">Send Message to All Creators</strong>
											<div className="mt-2 space-y-3">
												<input
													type="text"
													placeholder="Subject"
													className="w-full p-2 border rounded outline-none"
													value={messageForm.subject}
													onChange={(e) => setMessageForm(prev => ({ ...prev, subject: e.target.value }))}
													required
												/>
												<textarea
													placeholder="Message"
													className="w-full p-2 border rounded outline-none resize-none"
													rows="3"
													value={messageForm.message}
													onChange={(e) => setMessageForm(prev => ({ ...prev, message: e.target.value }))}
													required
													resize-none
												/>
												<button
													onClick={handleSendMessage}
													className="w-full bg-indigo-600 text-white py-2 rounded hover:bg-indigo-700"
												>
													Send Message
												</button>
											</div>
										</div>
									</Popover.Panel>
								</Transition>
							</>
						)}
					</Popover>

					<Popover className="relative">
						{({ open }) => (
							<>
								<Popover.Button
									className={classNames(
										open && 'bg-gray-100',
										'group inline-flex items-center rounded-sm p-2 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
									)}
								>
									<HiOutlineBell className="w-5 h-5" />
								</Popover.Button>
								<Transition
									as={Fragment}
									enter="transition ease-out duration-200"
									enterFrom="opacity-0 translate-y-1"
									enterTo="opacity-100 translate-y-0"
									leave="transition ease-in duration-150"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-1"
								>
									<Popover.Panel className="fixed left-1/2 transform -translate-x-1/2 w-[95%] md:w-[400px] lg:absolute lg:left-auto lg:right-0 lg:w-[320px] lg:transform-none top-[64px] z-10">
										<div className="mx-2 md:mx-auto bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
											<strong className="text-gray-700 font-medium">Notifications</strong>
											<div className="mt-2 py-1 text-sm">
												<div className="p-2 hover:bg-gray-100 cursor-pointer">
													<div className="font-medium">Welcome to BookMeSocial!</div>
													<div className="text-gray-500">Thank you for joining our platform.</div>
													<div className="text-xs text-gray-400 mt-1">2 hours ago</div>
												</div>
												<div className="p-2 hover:bg-gray-100 cursor-pointer">
													<div className="font-medium">Profile Update</div>
													<div className="text-gray-500">Please complete your profile information.</div>
													<div className="text-xs text-gray-400 mt-1">1 day ago</div>
												</div>
												<div className="p-2 hover:bg-gray-100 cursor-pointer">
													<div className="font-medium">System Update</div>
													<div className="text-gray-500">We've added new features to improve your experience.</div>
													<div className="text-xs text-gray-400 mt-1">3 days ago</div>
												</div>
											</div>
										</div>
									</Popover.Panel>
								</Transition>
							</>
						)}
					</Popover>

					<Menu as="div" className="relative">
						<Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
							<span className="sr-only">Open user menu</span>
							<div
								className="h-8 w-8 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
								style={{ backgroundImage: 'url("")' }}
							>
								<span className="sr-only">User Avatar</span>
							</div>
						</Menu.Button>
						{/* Profile Dropdown */}
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="fixed left-1/2 transform -translate-x-1/2 w-[95%] md:w-[400px] lg:absolute lg:left-auto lg:right-0 lg:w-56 lg:transform-none top-[64px] z-10 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
								<Menu.Item>
									{({ active }) => (
										<div
											onClick={() => navigate('/profile')}
											className={classNames(
												active && 'bg-gray-100',
												'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
											)}
										>
											Your Profile
										</div>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<div
											onClick={() => navigate('/settings')}
											className={classNames(
												active && 'bg-gray-100',
												'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
											)}
										>
											Settings
										</div>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<div
											onClick={() => handleLogout()}
											className={classNames(
												active && 'bg-gray-100',
												'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
											)}
										>
											Sign out
										</div>
									)}
								</Menu.Item>
							</Menu.Items>
						</Transition>
					</Menu>
				</div>
			</div>

			{/* Desktop view buttons - right aligned */}
			<div className="hidden xl:flex xl:items-center xl:gap-2 xl:ml-auto">
				<Popover className="relative">
					{({ open }) => (
						<>
							<Popover.Button
								className={classNames(
									open && 'bg-gray-100',
									'group inline-flex items-center rounded-sm p-2 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
								)}
								style={{ backgroundImage: 'url("")' }}
							>
								<HiOutlineChatAlt className="w-5 h-5" />
							</Popover.Button>
							{/* Chat Popover Panel - Updated for desktop */}
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-[280px]">
									<div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
										<strong className="text-gray-700 font-medium">Send Message to All Creators</strong>
										<div className="mt-2 space-y-3">
											<input
												type="text"
												placeholder="Subject"
												className="w-full p-2 border rounded outline-none"
												value={messageForm.subject}
												onChange={(e) => setMessageForm(prev => ({ ...prev, subject: e.target.value }))}
											/>
											<textarea
												placeholder="Message"
												className="w-full p-2 border rounded outline-none"
												rows="3"
												value={messageForm.message}
												onChange={(e) => setMessageForm(prev => ({ ...prev, message: e.target.value }))}
											/>
											<button
												onClick={handleSendMessage}
												className="w-full bg-indigo-600 text-white py-2 rounded hover:bg-indigo-700"
											>
												Send Message
											</button>
										</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>

				<Popover className="relative">
					{({ open }) => (
						<>
							<Popover.Button
								className={classNames(
									open && 'bg-gray-100',
									'group inline-flex items-center rounded-sm p-2 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
								)}
								style={{ backgroundImage: 'url("")' }}
							>
								<HiOutlineBell className="w-5 h-5" />
							</Popover.Button>
							{/* Notification Popover Panel */}
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-[280px]">
									<div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
										<strong className="text-gray-700 font-medium">Notifications</strong>
										<div className="mt-2 py-1 text-sm">
											<div className="p-2 hover:bg-gray-100 cursor-pointer">
												<div className="font-medium">Welcome to BookMeSocial!</div>
												<div className="text-gray-500">Thank you for joining our platform.</div>
												<div className="text-xs text-gray-400 mt-1">2 hours ago</div>
											</div>
											<div className="p-2 hover:bg-gray-100 cursor-pointer">
												<div className="font-medium">Profile Update</div>
												<div className="text-gray-500">Please complete your profile information.</div>
												<div className="text-xs text-gray-400 mt-1">1 day ago</div>
											</div>
											<div className="p-2 hover:bg-gray-100 cursor-pointer">
												<div className="font-medium">System Update</div>
												<div className="text-gray-500">We've added new features to improve your experience.</div>
												<div className="text-xs text-gray-400 mt-1">3 days ago</div>
											</div>
										</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>

				<Menu as="div" className="relative">
					<Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
						<span className="sr-only">Open user menu</span>
						<div
							className="h-8 w-8 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
							style={{ backgroundImage: 'url("")' }}
						>
							<span className="sr-only">User Avatar</span>
						</div>
					</Menu.Button>
					{/* Profile Dropdown */}
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="absolute right-0 z-10 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
							<Menu.Item>
								{({ active }) => (
									<div
										onClick={() => navigate('/profile')}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
										)}
									>
										Your Profile
									</div>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<div
										onClick={() => navigate('/settings')}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
										)}
									>
										Settings
									</div>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<div
										onClick={() => handleLogout()}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
										)}
									>
										Sign out
									</div>
								)}
							</Menu.Item>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>
		</div>
	)
}