import { HiOutlineCursorClick, HiClipboardList, HiPlusCircle, HiOutlineSpeakerphone, HiUserAdd, HiUser, HiOutlineClipboardList } from 'react-icons/hi'
import { FaInstagram } from 'react-icons/fa';

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Home',
        path: '/admin-dashboard',
    },
    {
        key: 'BankInfo',
        label: 'Payment Details',
        path: '/payment-details',
    },
    {
        key: 'IgData',
        label: 'Instagram',
        path: '/ig-profiles',
    },
]

export const APPROVAL_SIDEBAR_LINKS = [
    {
        key: 'Brand',
        label: 'Brand',
        path: '/brand',
        icon: <HiOutlineSpeakerphone />
    },
    {
        key: 'Partner',
        label: 'Partner',
        path: '/creator',
        icon: <HiUserAdd />
    },
]

export const APPROVED_SIDEBAR_LINKS = [
    {
        key: 'Brand',
        label: 'Brand',
        path: '/brandapprove',
        icon: <HiOutlineSpeakerphone />
    },
    {
        key: 'Creator',
        label: 'Partner',
        path: '/creatorapprove',
        icon: <HiUser />
    }
]

export const CAMPAIGN_SIDEBAR_LINKS = [
    {
        key: 'Createcampaign',
        label: 'Create Campaign',
        path: '/createcampaign',
        icon: <HiOutlineCursorClick />
    },
    {
        key: 'Creator',
        label: 'Campaign List',
        path: '/campaignlist',
        icon: <HiClipboardList />
    },
    {
        key: 'Affiliatecampaign',
        label: 'Create Affiliate Campaign',
        path: '/createaffiliatecampaign',
        icon: <HiPlusCircle />
    },
    {
        key: 'Affiliate',
        label: 'Affiliate Campaign List',
        path: '/affiliatecampaignlist',
        icon: <HiOutlineClipboardList />
    }
]
