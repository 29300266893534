import React, { useState } from 'react';
import Modal from 'react-modal';
import { EmailIcon, WhatsappIcon } from 'react-share';
import axios from 'axios';
import { API_URL } from '../../constant/constance';
import { toast } from 'react-toastify';
import { PuffLoader } from 'react-spinners';

const getShareableImageUrl = async (base64Image, title) => {
    try {
        const response = await axios.post(`${API_URL}/api/campaign/upload_image`, { base64Image, title });
        return response.data.imageUrl;
    } catch (error) {
        console.error('Error uploading image:', error);
        return null;
    }
};

const shareOnWhatsapp = (campaignId) => {
    const shareUrl = `${window.location.origin}/api/campaign-preview/${campaignId}`;
    const signupUrl = "https://bookmesocial.com/creatorregister";
    const shareMessage = `Check out this campaign:\nTo read more, please visit: ${signupUrl}`;
    const encodedMessage = encodeURIComponent(shareMessage);
    const whatsappUrl = `https://wa.me/?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
};

const sendCampaignEmail = async (campaign, recipientEmail) => {
    try {
        const response = await axios.post(`${API_URL}/api/campaign/send-campaign-email`, {
            email: recipientEmail,
            campaign: {
                _id: campaign._id,
                name: campaign.name,
                brandname: campaign.brandname,
                description: campaign.description,
                imageUrl: campaign.imageUrl,
            },
        });

        if (response.status === 200) {
            toast.success('Campaign email sent successfully!');
        } else {
            toast.error('Failed to send campaign email.');
        }
    } catch (error) {
        toast.error('Error sending email');
        console.error('Error:', error);
    }
};

const AffiliateCampaignDetailsModal = ({ isOpen, onRequestClose, campaign }) => {
    const [isEmailFormVisible, setEmailFormVisible] = useState(false);
    const [recipientEmail, setRecipientEmail] = useState('');
    const [isSending, setIsSending] = useState(false);

    // const handleSendEmail = async () => {
    //     if (!recipientEmail) {
    //         alert("Please enter an email address.");
    //         return;
    //     }

    //     setIsSending(true);
    //     try {
    //         await sendCampaignEmail(campaign, recipientEmail);
    //         setEmailFormVisible(false);
    //         setRecipientEmail('');
    //     } finally {
    //         setIsSending(false);
    //     }
    // };

    if (!campaign) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Campaign Details"
            className="fixed inset-0 flex items-center justify-center z-50 p-2 sm:p-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
                {/* Header */}
                <div className="bg-white p-3 sm:p-4 border-b border-gray-200">
                    <h2 className="text-lg sm:text-2xl font-semibold">{campaign.offer_name}</h2>
                </div>

                {/* Content */}
                <div className="p-3 sm:p-4 space-y-3 sm:space-y-4">
                    {campaign.logo && (
                        <div className="relative w-full h-auto overflow-hidden rounded-lg border border-gray-200">
                            <div className="w-full h-0 pb-[56.25%] relative">
                                <img
                                    src={campaign.logo}
                                    // alt={campaign.name}
                                    className="absolute inset-0 w-full h-full object-contain bg-gray-50"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = 'fallback-image-url.jpg'; // Add a fallback image URL
                                    }}
                                />
                            </div>
                            {/* Optional: Add zoom functionality */}
                            <button
                                onClick={() => window.open(campaign.logo, '_blank')}
                                className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-70 transition-opacity"
                                title="View full image"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                                </svg>
                            </button>
                        </div>
                    )}

                    {/* Campaign Details */}
                    <div className="space-y-2 sm:space-y-3">
                        <DetailRow label="Offer ID" value={campaign.offerid} />
                        <DetailRow label="Cateogry" value={campaign.category || 'N/A'} />
                        <DetailRow label="Model" value={campaign.model_advertiser} />
                        <DetailRow label="Revenue" value={`${campaign.price_advertiser || '0'} ${campaign.currency || 'INR'}`} />
                        <DetailRow label="Payout" value={`${campaign.price_affiliate || '0'} ${campaign.currency || 'INR'}`} />
                        <DetailRow label="Advertiser ID" value={campaign.advertiser_id} />

                        {/* Longer text sections */}
                        <div className="space-y-1">
                            <h3 className="text-sm sm:text-base font-semibold">Description</h3>
                            <p className="text-xs sm:text-sm text-gray-700 indent-3 whitespace-pre-wrap">
                                {campaign.offer_description || 'No Description'}
                            </p>
                        </div>
                        {/* <div className="space-y-1">
                            <h3 className="text-sm sm:text-base font-semibold">Campaign Brief</h3>
                            <p className="text-xs sm:text-sm text-gray-700 whitespace-pre-wrap">
                                {campaign.brief}
                            </p>
                        </div>
                        <div className="space-y-1">
                            <h3 className="text-sm sm:text-base font-semibold">Terms</h3>
                            <p className="text-xs sm:text-sm text-gray-700 whitespace-pre-wrap">
                                {campaign.terms}
                            </p>
                        </div> */}
                    </div>

                    {/* Share Buttons */}
                    {/* <div className="flex flex-wrap gap-3 justify-center pt-2">
                        <button
                            onClick={() => shareOnWhatsapp(campaign._id)}
                            className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition-colors"
                        >
                            <WhatsappIcon size={24} round />
                            <span className="text-sm">Share on WhatsApp</span>
                        </button>
                        <button
                            onClick={() => setEmailFormVisible(!isEmailFormVisible)}
                            className="flex items-center gap-2 bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors"
                        >
                            <EmailIcon size={24} round bgStyle={{ fill: "#6366f1" }} />
                            <span className="text-sm">Share via Email</span>
                        </button>
                    </div>

                    {isEmailFormVisible && (
                        <div className="mt-4 p-3 bg-gray-50 rounded-lg">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Enter Creator's email:
                            </label>
                            <input
                                type="email"
                                value={recipientEmail}
                                onChange={(e) => setRecipientEmail(e.target.value)}
                                placeholder="Recipient's email"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                                disabled={isSending}
                            />
                            <button
                                onClick={handleSendEmail}
                                disabled={isSending}
                                className="mt-3 w-full bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-600 transition-colors text-sm disabled:opacity-70 disabled:cursor-not-allowed"
                            >
                                {isSending ? (
                                    <div className="flex items-center justify-center gap-2">
                                        <PuffLoader size={20} color="#ffffff" />
                                        <span>Sending...</span>
                                    </div>
                                ) : (
                                    'Send Email'
                                )}
                            </button>
                        </div>
                    )} */}
                </div>

                {/* Footer */}
                <div className="sticky bottom-0 bg-white p-3 sm:p-4 border-t border-gray-200">
                    <button
                        onClick={onRequestClose}
                        className="w-full bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors text-sm sm:text-base"
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
};

// Helper component for detail rows
const DetailRow = ({ label, value }) => (
    <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-2">
        <span className="text-xs sm:text-sm font-semibold text-gray-600">{label}:</span>
        <span className="text-xs sm:text-sm text-gray-700">{value}</span>
    </div>
);

export default AffiliateCampaignDetailsModal;
