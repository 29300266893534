import axios from 'axios'
import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PuffLoader from 'react-spinners/PuffLoader'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API_URL } from '../../constant/constance'

export default function CreateAffiliateCampaign() {
    const modelOptions = [
        { value: 'CPA', label: 'CPA (Cost Per Action)' },
        { value: 'CPS', label: 'CPS (Cost Per Sale)' },
    ];

    const countryOptions = [
        { value: "IN", label: 'India' }
    ];

    const currencyOptions = [
        { value: "INR", label: 'INR' }
    ];

    const [formData, setFormData] = useState({
        offer_name: '',
        advertiser_id: null,
        offer_url: '',
        logo: null,
        currency: '',
        price_advertiser: '',
        price_affiliate: null,
        model_advertiser: '',
        model_affiliate: '',
        target_country: '',
        offer_terms: '',
    })
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles[0]) {
            const formatDataForImage = new FormData();
            formatDataForImage.append('logo', acceptedFiles[0]);

            setFileName(acceptedFiles[0].name);

            try {
                const response = await axios.post(`${API_URL}/api/affiliate/upload_campaign_image`,
                    formatDataForImage,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            setUploadProgress(progress)
                        }
                    }
                );

                if (response.data.success) {
                    setImageUrl(response.data.imageUrl);
                    setFormData(prev => ({
                        ...prev,
                        logo: response.data.imageUrl
                    }));
                }

            } catch (error) {
                toast.error('Failed to upload image');
                console.error('Error uploading image:', error);
            } finally {
                setTimeout(() => {
                    setUploadProgress(0);
                }, 1000);
            }
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png']
        }
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'price_affiliate' ? parseFloat(value) || ''
                : name === 'advertiser_id' ? parseInt(value) || ''
                    : value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const advertiserId = parseInt(formData.advertiser_id);
        if (!advertiserId || isNaN(advertiserId)) {
            toast.error('Please enter a valid Advertiser ID');
            setLoading(false);
            return;
        }

        if (!formData.price_affiliate || isNaN(formData.price_affiliate)) {
            toast.error('Please enter a valid Affiliate Price');
            setLoading(false);
            return;
        }

        try {
            const payload = {
                offer_name: formData.offer_name,
                advertiser_id: advertiserId,
                offer_url: formData.offer_url,
                logo: imageUrl,
                currency: formData.currency,
                price_advertiser: formData.price_advertiser,
                price_affiliate: formData.price_affiliate,
                model_advertiser: formData.model_advertiser,
                model_affiliate: formData.model_affiliate,
                target_country: formData.target_country,
                offer_terms: formData.offer_terms,
            };

            const response = await axios.post(`${API_URL}/api/affiliate/create_affiliate_campaign`, payload)

            if (response.status === 200) {
                toast.success('Campaign created successfully!')
                window.location.href = '/createaffiliatecampaign'
            } else {
                toast.error('Failed to create campaign. Please try again.')
            }
        } catch (error) {
            console.log(error);
            toast.error('Error creating campaign. Please try again later.')
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='bg-sky-500 min-h-screen w-full flex items-start sm:items-center justify-center p-4 sm:px-6 lg:px-8 py-4 sm:py-8'>
            <div className="w-full max-w-3xl bg-white rounded-lg shadow-md my-4 sm:my-6">
                <div className="p-6 sm:p-6 md:p-8">
                    <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 text-center mb-4 sm:mb-6 md:mb-8">
                        Create Affiliate Campaign
                    </h1>

                    <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4 md:space-y-6">
                        {/* Input Field Component */}
                        {inputField("Offer Name", "offer_name", "text")}
                        {inputField("Advertiser ID", "advertiser_id", "number")}
                        {inputField("Offer URL", "offer_url", "url")}

                        {/* Image Upload Section */}
                        <div className="space-y-1 sm:space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Upload Campaign Image
                            </label>
                            <div
                                {...getRootProps()}
                                className={`relative w-full px-3 sm:px-4 py-6 sm:py-8 border-2 border-dashed rounded-lg transition-colors duration-200 ${isDragActive ? 'border-blue-400' : 'border-gray-300'
                                    }`}
                            >
                                <input {...getInputProps()} name="logo" />
                                <div className="text-center">
                                    {fileName ? (
                                        <div className="space-y-1">
                                            <p className="text-xs sm:text-sm truncate">
                                                {fileName}
                                            </p>
                                        </div>
                                    ) : (
                                        <p className="text-xs sm:text-sm text-gray-500">
                                            Drag & Drop your image here or click to select file
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Progress Bar */}
                        {uploadProgress > 0 && (
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Upload Progress
                                </label>
                                <progress
                                    value={uploadProgress}
                                    max="100"
                                    className="w-full h-2 rounded"
                                />
                            </div>
                        )}

                        {/* Basic Fields */}
                        {dropDownField("Currency", "currency", currencyOptions)}
                        {inputField("Advertiser Price", "price_advertiser", "number")}
                        {inputField("Affiliate Price", "price_affiliate", "number")}
                        {dropDownField("Model from the Advertiser", "model_advertiser", modelOptions)}
                        {dropDownField("Model for the Affiliate", "model_affiliate", modelOptions)}
                        {dropDownField("Country", "target_country", countryOptions)}
                        {textareaField("Terms of the Campaign", "offer_terms")}
                        {/* {inputField("Apply Before", "apply_before", "date")} */}


                        {/* Submit Button */}
                        <div className="pt-4 sm:pt-6">
                            {loading ? (
                                <div className="flex justify-center">
                                    <PuffLoader color="#3b82f6" size={60} loading={loading} />
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full py-2.5 sm:py-3 px-4 sm:px-6 text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition duration-300 text-sm sm:text-base font-medium"
                                >
                                    Create Affiliate Campaign
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

    function inputField(label, name, type) {
        const isRequired = ["offer_name", "advertiser_id", "offer_url"].includes(name);

        return (
            <div className="space-y-1 sm:space-y-2">
                <label className="block text-xs sm:text-sm font-medium text-gray-700">
                    {label}
                    {isRequired && <span className='text-red-500 ml-1'>*</span>}
                </label>
                <input
                    type={type}
                    min={0}
                    name={name}
                    required={isRequired}
                    onChange={handleChange}
                    className="w-full px-2.5 sm:px-3 py-1.5 sm:py-2 text-xs sm:text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-colors duration-200 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                />
            </div>
        );
    }

    function dropDownField(label, name, options) {
        return (
            <div className="space-y-1 sm:space-y-2">
                <label className="block text-xs sm:text-sm font-medium text-gray-700">
                    {label}
                </label>
                <select
                    name={name}
                    onChange={handleChange}
                    className='w-full px-2.5 sm:px-3 py-1.5 sm:py-2 text-xs sm:text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-colors duration-200'
                >
                    <option value="">Select a Value</option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    function textareaField(label, name) {
        return (
            <div className="space-y-1 sm:space-y-2">
                <label className="block text-xs sm:text-sm font-medium text-gray-700">
                    {label}
                </label>
                <textarea
                    name={name}
                    onChange={handleChange}
                    rows={4}
                    className="w-full px-2.5 sm:px-3 py-1.5 sm:py-2 text-xs sm:text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-colors duration-200 resize-none"
                />
            </div>
        );
    }
}