import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../../constant/constance'
import { toast } from 'react-toastify'
import DataTable from 'react-data-table-component'
import { Country, State } from "country-state-city"
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { BsChatTextFill } from 'react-icons/bs'
import { ImCheckmark, ImCross } from 'react-icons/im'

function Creator() {
    const [creatorData, setCreatorData] = useState([])
    const [isupdated, setIsupdated] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const getFullLocationData = (countryCode, stateCode) => {
        const country = Country.getCountryByCode(countryCode)?.name || "N/A";
        const state = State.getStateByCodeAndCountry(stateCode, countryCode)?.name || "N/A";
        return { country, state };
    };

    // Filtered data
    const filteredItems = creatorData.filter(
        (item) => item.email_id && item.email_id.toLowerCase().includes(filterText.toLowerCase())
    )

    const socialMediaIcons = {
        instagram: <FaInstagram title="Instagram" />,
        facebook: <FaFacebook title="Facebook" />,
        others: <BsChatTextFill title="others" />,
        youtube: <FaYoutube title="Youtube" />
    }


    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '50px',
            compact: true,
            style: {
                paddingLeft: '24px',
            },
            headCell: {
                style: {
                    paddingLeft: '24px',
                },
            },
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            wrap: true,
            minWidth: '220px',
            compact: true
        },
        {
            name: 'Email',
            selector: (row) => row.email_id,
            wrap: true,
            minWidth: '220px',
            compact: true
        },
        {
            name: 'Channel Link',
            selector: (row) => (
                <div className='me-3'>
                    <a
                        href={row.channelLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        className="text-indigo-600 hover:underline flex items-center"
                    >
                        {row.channelLink}
                    </a>
                </div>
            ),
            wrap: true,
            minWidth: '250px',
            compact: true
        },
        {
            name: 'DOB',
            selector: (row) => row.dob,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Gender',
            selector: (row) => row.gender,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Phone',
            selector: (row) => row.mobile_number,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Country',
            selector: (row) => {
                const { countryName } = getFullLocationData(row.country, row.state, row.city);
                return countryName;
            },
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'State',
            selector: (row) => {
                const { stateName } = getFullLocationData(row.country, row.state, row.city);
                return stateName;
            },
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'City',
            selector: (row) => row.city,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Channel',
            selector: (row) => row.channel_site_name,
            wrap: true,
            minWidth: '150px',
            compact: true
        },
        {
            name: 'Social',
            minWidth: '180px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.social_media && row.social_media.length > 0 ? (
                        row.social_media.slice(0, 4).map((media, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={media}
                            >
                                {socialMediaIcons[media] || media}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Followers',
            selector: (row) => row.followers_range,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'IG Reel [Max Rate]',
            selector: (row) => row.max_rate_ig_reel,
            wrap: true,
            minWidth: '160px',
            compact: true
        },
        {
            name: 'IG Reel [Min Rate]',
            selector: (row) => row.min_rate_ig_reel,
            wrap: true,
            minWidth: '140px',
            compact: true
        },
        {
            name: 'YT Full [Max Rate]',
            selector: (row) => row.max_rate_youtube_full,
            wrap: true,
            minWidth: '160px',
            compact: true
        },
        {
            name: 'YT Full [Min Rate]',
            selector: (row) => row.min_rate_youtube_full,
            wrap: true,
            minWidth: '140px',
            compact: true
        },
        {
            name: 'YT Short [Max Rate]',
            selector: (row) => row.max_rate_youtube_short,
            wrap: true,
            minWidth: '160px',
            compact: true
        },
        {
            name: 'YT Short [Min Rate]',
            selector: (row) => row.min_rate_youtube_short,
            wrap: true,
            minWidth: '160px',
            compact: true
        },
        {
            name: 'Other Price',
            selector: (row) => row.other_price,
            wrap: true,
            minWidth: '140px',
            compact: true
        },
        {
            name: 'Campaign Types',
            wrap: true,
            minWidth: '180px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.campaign_types && row.campaign_types.length > 0 ? (
                        row.campaign_types.slice(0, 4).map((campaign, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={campaign}
                            >
                                {campaign}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Other Campaign Type',
            selector: (row) => row.other_campaign_type,
            wrap: true,
            minWidth: '180px',
            compact: true
        },
        {
            name: 'Content Language',
            selector: (row) => row.content_lang,
            wrap: true,
            minWidth: '150px',
            compact: true
        },
        {
            name: 'Content Types',
            wrap: true,
            minWidth: '190px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.content_types && row.content_types.length > 0 ? (
                        row.content_types.slice(0, 4).map((content, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={content}
                            >
                                {content}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Contract Partnerships',
            wrap: true,
            minWidth: '180px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.contract_partnerships && row.contract_partnerships.length > 0 ? (
                        row.contract_partnerships.slice(0, 4).map((contract, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={contract}
                            >
                                {contract}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Interest Topics',
            wrap: true,
            minWidth: '180px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.interest_topics && row.interest_topics.length > 0 ? (
                        row.interest_topics.slice(0, 4).map((topics, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={topics}
                            >
                                {topics}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Other Interest Topics',
            selector: (row) => row.other_interest_topic,
            wrap: true,
            minWidth: '180px',
            compact: true,
        },
        {
            name: 'Payout Types',
            wrap: true,
            minWidth: '180px',
            compact: true,
            cell: (row) => (
                <div className="flex flex-wrap items-center gap-1">
                    {row.payout_types && row.payout_types.length > 0 ? (
                        row.payout_types.slice(0, 4).map((payout, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 px-1.5 py-0.5 rounded-md text-sm flex items-center"
                                title={payout}
                            >
                                {payout}
                            </span>
                        ))
                    ) : (
                        <span className="text-gray-500 text-sm">None</span>
                    )}
                </div>
            )
        },
        {
            name: 'Action',
            minWidth: '100px',
            compact: true,
            cell: (row) => (
                <div className="flex items-center gap-1">
                    <button
                        type="button"
                        className="p-1.5 text-red-600 hover:bg-red-50 rounded"
                        onClick={() => handleReject(row._id)}
                    >
                        <ImCross size={12} />
                    </button>
                    <button
                        type="button"
                        className="p-1.5 text-green-600 hover:bg-green-50 rounded"
                        onClick={() => handleAprovel(row._id)}
                    >
                        <ImCheckmark size={12} />
                    </button>
                </div>
            )
        }
    ]

    const sendEmail = (userId) => {
        axios
            .post(`${API_URL}/api/creatorreg/send-email`, { user_id: userId })
            .then((response) => {
                console.log('Email sent successfully:', response.data)
                window.location.reload()
            })
            .catch((error) => {
                console.error('Error sending email:', error)
            })
    }

    useEffect(() => {
        const fetchCreatorData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/creatorreg/creater`)
                setCreatorData(response.data.data)
            } catch (error) {
                console.error('Error fetching creator data:', error)
            }
        }
        fetchCreatorData()
    }, [isupdated])

    const handleAprovel = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/aprover_creater/${itemId}`)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const handleReject = async (itemId) => {
        try {
            const response = await axios.get(`${API_URL}/api/creatorreg/reject_creater/${itemId}`)
            console.log(response)
            if (response.status === 200) {
                toast.success(response.data.message)
                setIsupdated((prev) => !prev)
            }
        } catch (error) {
            toast.error(error.message)
            console.error('Error', error)
        }
    }

    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-3 sm:p-4 rounded-lg shadow-sm border border-gray-200 transition-all duration-300 hover:shadow-md">
            <div className="flex justify-between items-center mb-3">
                <span className="text-sm font-semibold text-gray-600">#{index + 1}</span>
                <div className="flex items-center gap-2">
                    <button
                        type="button"
                        className="p-1.5 text-red-600 hover:bg-red-50 rounded"
                        onClick={() => handleReject(data._id)}
                    >
                        <ImCross className="w-3 h-3" />
                    </button>
                    <button
                        type="button"
                        className="p-1.5 text-green-600 hover:bg-green-50 rounded"
                        onClick={() => handleAprovel(data._id)}
                    >
                        <ImCheckmark className="w-3 h-3" />
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <DataRow label="Name" value={data.name} />
                <DataRow label="Email" value={data.email_id} />
                <DataRow
                    label="Channel Link"
                    value={
                        <a
                            href={data.channelLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            className="truncate text-indigo-600 hover:underline flex items-center max-w-52 overflow-hidden whitespace-nowrap text-ellipsis"
                        >
                            {data.channelLink}
                        </a>
                    }
                />
                <DataRow label="DOB" value={data.dob} />
                <DataRow label="Gender" value={data.gender} />
                <DataRow label="Phone" value={data.mobile_number} />
                <DataRow
                    label="Country"
                    value={getFullLocationData(data.country, data.state, data.city).countryName}
                />
                <DataRow
                    label="State"
                    value={getFullLocationData(data.country, data.state, data.city).stateName}
                />
                <DataRow label="City" value={data.city} />
                <DataRow label="Channel" value={data.channel_site_name} />
                <DataRow label="Followers" value={data.followers_range} />

                <div className="sm:col-span-1">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Social Media</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.social_media && data.social_media.length > 0 ? (
                            data.social_media.slice(0, 4).map((media, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={media}
                                >
                                    {socialMediaIcons[media] || media}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No social media accounts</span>
                        )}
                    </div>
                </div>

                <DataRow label="IG Reel [Max Rate]" value={data.max_rate_ig_reel} />
                <DataRow label="IG Reel [Min Rate]" value={data.min_rate_ig_reel} />
                <DataRow label="YT Full [Max Rate]" value={data.max_rate_youtube_full} />
                <DataRow label="YT Full [Min Rate]" value={data.min_rate_youtube_full} />
                <DataRow label="YT Short [Max Rate]" value={data.max_rate_youtube_short} />
                <DataRow label="YT Short [Min Rate]" value={data.min_rate_youtube_short} />
                <DataRow label="Other Price" value={data.other_price} />

                <div className="sm:col-span-1">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Campaign Types</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.campaign_types && data.campaign_types.length > 0 ? (
                            data.campaign_types.slice(0, 4).map((campaign, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={campaign}
                                >
                                    {campaign}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No campaign types</span>
                        )}
                    </div>
                </div>

                <DataRow label="Other Campaign Type" value={data.other_campaign_type} />
                <DataRow label="Content Language" value={data.content_lang} />

                <div className="sm:col-span-1">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Content Types</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.content_types && data.content_types.length > 0 ? (
                            data.content_types.slice(0, 4).map((content, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={content}
                                >
                                    {content}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No content types</span>
                        )}
                    </div>
                </div>

                <div className="sm:col-span-1">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Contract Partnerships</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.contract_partnerships && data.contract_partnerships.length > 0 ? (
                            data.contract_partnerships.slice(0, 4).map((contract, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={contract}
                                >
                                    {contract}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No contract partnerships</span>
                        )}
                    </div>
                </div>

                <div className="sm:col-span-1">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Interest Topics</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.interest_topics && data.interest_topics.length > 0 ? (
                            data.interest_topics.slice(0, 4).map((topics, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={topics}
                                >
                                    {topics}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No interest topics</span>
                        )}
                    </div>
                </div>

                <DataRow label="Other Interest Topics" value={data.other_interest_topic} />

                <div className="sm:col-span-1">
                    <span className="text-xs font-medium text-gray-500 block mb-1">Payout Types</span>
                    <div className="flex flex-wrap gap-1.5">
                        {data.payout_types && data.payout_types.length > 0 ? (
                            data.payout_types.slice(0, 4).map((payout, index) => (
                                <span
                                    key={index}
                                    className="bg-gray-100 px-2 py-1 rounded text-xs flex items-center gap-1"
                                    title={payout}
                                >
                                    {payout}
                                </span>
                            ))
                        ) : (
                            <span className="text-gray-500 text-xs">No payout types</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    const DataRow = ({ label, value }) => (
        <div className="flex flex-col gap-0.5 transition-all duration-300">
            <span className="text-xs font-medium text-gray-500 transition-colors duration-300">{label}</span>
            <span className="text-sm text-gray-900 break-words transition-colors duration-300">{value || '-'}</span>
        </div>
    );

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Email ID"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 border rounded w-full max-w-lg text-sm outline-none transition-all duration-300"
                />
            </div>

            {/* Mobile View */}
            <div className="xl:hidden px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="grid gap-3 sm:gap-4 transition-all duration-300">
                    {(filterText.length > 0 ? filteredItems : creatorData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden xl:block px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="border rounded shadow-sm overflow-hidden transition-all duration-300">
                    <div className='overflow-x-auto w-[76rem]'>
                        <DataTable
                            title="Creator"
                            columns={columns}
                            data={filterText.length > 0 ? filteredItems : creatorData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            responsive
                            customStyles={{
                                table: {
                                    style: {
                                        minWidth: '100%',
                                        transition: 'all 0.3s ease-in-out',
                                        overflowX: 'auto'
                                    },
                                },
                                rows: {
                                    style: {
                                        minHeight: '52px',
                                        fontSize: '13px',
                                        backgroundColor: 'white',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: '#f9fafb',
                                        },
                                        borderBottom: '1px solid #f0f0f0',
                                    },
                                },
                                headRow: {
                                    style: {
                                        backgroundColor: '#f8f9fa',
                                        minHeight: '48px',
                                        borderBottom: '2px solid #e5e7eb',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                },
                                headCells: {
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        padding: '12px 24px',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                    firstChild: {
                                        paddingLeft: '24px',
                                    },
                                },
                                cells: {
                                    style: {
                                        padding: '12px 24px',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                    firstChild: {
                                        paddingLeft: '24px',
                                    },
                                },
                            }}
                            striped={false}
                            highlightOnHover
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Creator
