import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_URL } from '../constant/constance'
import DataTable from 'react-data-table-component'

function InstagramData() {
    const [igData, setIgData] = useState([])
    const [isupdated, setIsupdated] = useState(false)
    const [filterText, setFilterText] = useState('')
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    // Filtered data
    const filteredItems = igData.filter(
        (item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    )

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '50px',
            compact: true,
            style: {
                paddingLeft: '24px',
            },
            headCell: {
                style: {
                    paddingLeft: '24px',
                },
            },
        },
        {
            name: 'Profile Picture',
            selector: (row) => (
                <img
                    src={row.profile_picture_url}
                    alt={row.name || "Profile Picture"}
                    className="w-16 h-16 rounded-full object-cover"
                />
            ),
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            wrap: true,
            minWidth: '200px',
            compact: true
        },
        {
            name: 'Username',
            selector: (row) => row.username,
            wrap: true,
            minWidth: '200px',
            compact: true
        },
        {
            name: 'Account Type',
            selector: (row) => row.account_type,
            wrap: true,
            minWidth: '120px',
            compact: true
        },
        {
            name: 'Website',
            selector: (row) => (
                <div className='me-3'>
                    <a
                        href={row.website}
                        target='_blank'
                        rel='noopener noreferrer'
                        className="text-indigo-600 truncate hover:underline flex items-center"
                    >
                        {row.website || <span className='text-black'>-</span>}
                    </a>
                </div>
            ),
            wrap: true,
            minWidth: '280px',
            compact: true
        },
        {
            name: 'Followers',
            selector: (row) => row.followers_count,
            wrap: true,
            minWidth: '100px',
            compact: true
        },
        {
            name: 'Follows',
            selector: (row) => row.follows_count,
            wrap: true,
            minWidth: '100px',
            compact: true
        },
        {
            name: 'Biography',
            selector: (row) => row.biography || '-',
            wrap: true,
            minWidth: '220px',
            compact: true
        },
        {
            name: 'Media',
            selector: (row) => row.media_count,
            wrap: true,
            minWidth: '80px',
            compact: true
        }
    ]

    useEffect(() => {
        const fetchIGData = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/affiliate/igdata`)
                setIgData(response.data.data)
            } catch (error) {
                console.error('Error fetching creator data:', error)
            }
        }
        fetchIGData()
    }, [isupdated])

    const MobileCard = ({ data, index }) => (
        <div className="bg-white p-3 sm:p-4 rounded-lg shadow-sm border border-gray-200 transition-all duration-300 hover:shadow-md">
            <div className="flex justify-between items-center mb-3">
                <span className="text-sm font-semibold text-gray-600">#{index + 1}</span>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                <DataRow label="Profile Picture" value={data.profile_picture_url} />
                <DataRow label="Name" value={data.name} />
                <DataRow label="Username" value={data.username} />
                <DataRow label="Account Type" value={data.account_type} />
                <DataRow label="Website" value={data.website} />
                <DataRow label="Followers" value={data.followers_count} />
                <DataRow label="Follows" value={data.follows_count} />
                <DataRow label="Biography" value={data.biography} />
                <DataRow label="Media" value={data.media_count} />
            </div>
        </div>
    );

    const DataRow = ({ label, value }) => {
        const isImageURL = value && typeof value === 'string' && value.startsWith('http') && value.includes('cdninstagram.com');

        return (
            <div className="flex flex-col gap-0.5 transition-all duration-300">
                <span className="text-xs font-medium text-gray-500 transition-colors duration-300">{label}</span>
                {value && typeof value === 'string' && value.startsWith('http') ? (
                    isImageURL ? (
                        <img
                            src={value}
                            alt={label}
                            className="w-10 h-10 rounded-full object-cover transition-transform duration-300 hover:scale-105"
                        />
                    ) : (
                        <a href={value} target="_blank" rel="noopener noreferrer" className="text-sm text-indigo-600 hover:underline flex items-center max-w-52 overflow-hidden whitespace-nowrap">
                            {value}
                        </a>
                    )
                ) : (
                    <span className="text-sm text-gray-900 break-words transition-colors duration-300">{value || '-'}</span>
                )}
            </div>
        );
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="px-2 sm:px-4">
                <input
                    type="text"
                    placeholder="Search by Name"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    className="p-2 border rounded w-full max-w-lg text-sm outline-none transition-all duration-300"
                />
            </div>

            {/* Mobile View */}
            <div className="xl:hidden px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="grid gap-3 sm:gap-4 transition-all duration-300">
                    {(filterText.length > 0 ? filteredItems : igData).map((item, index) => (
                        <MobileCard key={item._id} data={item} index={index} />
                    ))}
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden xl:block px-2 sm:px-4 transition-all duration-300 ease-in-out">
                <div className="border rounded shadow-sm overflow-hidden transition-all duration-300">
                    <div className='overflow-x-auto w-[76rem]'>
                        <DataTable
                            title="IG Profiles"
                            columns={columns}
                            data={filterText.length > 0 ? filteredItems : igData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            responsive
                            customStyles={{
                                table: {
                                    style: {
                                        minWidth: '100%',
                                        transition: 'all 0.3s ease-in-out',
                                        overflowX: 'auto'
                                    },
                                },
                                rows: {
                                    style: {
                                        minHeight: '52px',
                                        fontSize: '13px',
                                        backgroundColor: 'white',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: '#f9fafb',
                                        },
                                        borderBottom: '1px solid #f0f0f0',
                                    },
                                },
                                headRow: {
                                    style: {
                                        backgroundColor: '#f8f9fa',
                                        minHeight: '48px',
                                        borderBottom: '2px solid #e5e7eb',
                                        transition: 'all 0.3s ease-in-out',
                                    },
                                },
                                headCells: {
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        padding: '12px 24px',
                                        transition: 'all 0.3s ease-in-out',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    },
                                    firstChild: {
                                        paddingLeft: '24px',
                                    },
                                },
                                cells: {
                                    style: {
                                        padding: '12px 24px',
                                        transition: 'all 0.3s ease-in-out',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    },
                                    firstChild: {
                                        paddingLeft: '24px',
                                    },
                                },
                            }}
                            striped={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstagramData